
























































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import DashboardMain from "@/components/DashboardMain.vue";
import {MenuCardItem} from "@/model/menu-card-item";
import {RoleSettings} from "@/model/role-settings";
import {defaultRoleSettings, Roles} from "@/constants/role.constants";

const Auth = namespace("Auth");
@Component({
  components: {DashboardMain}
})
export default class Trainer extends Vue {
  menuItems: MenuCardItem[] = [
    {
      id: 1,
      disabled: false,
      title: this.$t('cards.trainerBoard.cards.workOutPlanAdd.title') as string,
      subtitle: this.$t('cards.trainerBoard.cards.workOutPlanAdd.subtitle') as string,
      dark: false,
      to: '/trainer/workout-plan/add',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string,
      color: 'light-blue',
      icon: 'mdi-calendar-plus'
    },
    {
      id: 2,
      disabled: false,
      title: this.$t('cards.trainerBoard.cards.workOutPlanEdit.title') as string,
      subtitle: this.$t('cards.trainerBoard.cards.workOutPlanEdit.subtitle') as string,
      dark: false,
      to: '/trainer/workout-plan/edit-list',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string,
      color: 'lime',
      icon: 'mdi-calendar-edit'
    },
    {
      id: 3,
      disabled: false,
      title: this.$t('cards.trainerBoard.cards.workOutPlanList.title') as string,
      subtitle: this.$t('cards.trainerBoard.cards.workOutPlanList.subtitle') as string,
      dark: false,
      to: '/trainer/workout-plan/list',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string,
      color: 'light-green',
      icon: 'mdi-calendar-text-outline'
    },
    {
      id: 4,
      disabled: true,
      title: this.$t('cards.trainerBoard.cards.groups.title') as string,
      subtitle: this.$t('cards.trainerBoard.cards.groups.subtitle') as string,
      dark: false,
      to: '/trainer/groups',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string,
      color: 'orange',
      icon: 'mdi-account-group-outline'
    },
    {
      id: 5,
      disabled: true,
      title: this.$t('cards.trainerBoard.cards.calendar.title') as string,
      subtitle: this.$t('cards.trainerBoard.cards.calendar.subtitle') as string,
      dark: false,
      to: '/trainer/calendar',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string,
      color: 'amber',
      icon: 'mdi-calendar-outline'
    },
  ]

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Action
  private signOut!: () => void;

  roleSettings(role: string): RoleSettings {
    return defaultRoleSettings.find(r => r.authority === role)!
  }

  hasAuthority(authority: string): boolean {
    if (this.currentUser && this.currentUser.authorities) {
      return this.currentUser.authorities.includes(authority);
    }
    return false;
  }
}
